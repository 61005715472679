import ApiService from "@/core/services/ApiService";
import { Device } from "@/core/types/Device";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import * as utils from "../../core/helpers/utils";
@Module
export default class DevicesModule extends VuexModule {
  devices: Device[] = [];

  get allDevices(): Device[] {
    return this.devices;
  }
  @Mutation
  [Mutations.SET_DEVICES](data: Device[]) {
    this.devices = data;
  }

  @Action({ rawError: true })
  [Actions.GET_DEVICES]() {
    const devices: Device[] = [];
    return ApiService.get("v1/client", "devices")
      .then(({ data }) => {
        for (const device of data) {
          const alarm = device.lastPosition?.attributes?.alarm === "sos";
          const alarmType = alarm ? "SOS" : "WARNING";
          const speedKh = utils.calculateSpeed(device.lastPosition?.speed);
          const speed = device.lastPosition ? Math.round(speedKh) : 0;
          let speedAlarm = false;
          if (speed > 70) {
            speedAlarm = true;
          }
          const newPosition: Device = {
            _id: device._id,
            id: device.deviceId,
            name: device.name,
            coords: [
              device.lastPosition ? device.lastPosition.latitude : 0,
              device.lastPosition ? device.lastPosition.longitude : 0,
            ],
            alarm: alarm,
            alarmType: alarmType,
            icon: alarm
              ? "/media/map/markers/sos.png"
              : "/media/map/markers/marker.png",
            speed: speed,
            speedAlarm: speedAlarm,
          };
          if (device.lastPosition) {
            newPosition.lastSeen = utils.diff_hours(
              new Date(device.lastPosition.deviceTime),
              new Date()
            );
            newPosition.lastSeenText = utils.timeFormat(
              new Date().getTime() -
                new Date(device.lastPosition.deviceTime).getTime()
            );
          }
          devices.push(newPosition);
        }
        this.context.commit(Mutations.SET_DEVICES, devices);
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
