import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import * as utils from "../../core/helpers/utils";
import {
  AddMaintenanceProps,
  EditMaintenanceProps,
  Maintenance,
  RemoveMaintenanceImageProps,
} from "@/core/types/Maintenance";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Module
export default class MaintenancesModule extends VuexModule {
  maintenances: Maintenance[] = [];

  get allMaintenances(): Maintenance[] {
    return this.maintenances;
  }
  @Mutation
  [Mutations.SET_MAINTENANCES](data: Maintenance[]) {
    this.maintenances = data;
  }

  @Action({ rawError: true })
  [Actions.GET_MAINTENANCES]() {
    return ApiService.get("v1/client", "maintenances")
      .then((response) => {
        const data: Maintenance[] = response.data;
        const tempList: Maintenance[] = [];
        for (const maintenance of data) {
          maintenance.endedAt = utils.datetimeFormat(
            new Date(maintenance.endedAt)
          );
          maintenance.startedAt = utils.datetimeFormat(
            new Date(maintenance.startedAt)
          );
          tempList.push(maintenance);
        }

        this.context.commit(Mutations.SET_MAINTENANCES, tempList);
        return tempList;
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.DELETE_MAINTENANCE](id: string) {
    return ApiService.delete("/v1/client/maintenance?id=" + id, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Maintenance deleted successfully",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.EDIT_MAINTENANCE](props: EditMaintenanceProps) {
    const { formData, maintenanceId, userResponseCallback } = props;
    return ApiService.put(
      `/v1/client/maintenance?id=${maintenanceId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Maintenance updated successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            userResponseCallback(); //this.cancel();
          });
        }
        return status;
      })
      .catch((err) => console.log(err));
  }

  @Action({ rawError: true })
  [Actions.ADD_MAINTENANCE](props: AddMaintenanceProps) {
    const { formData } = props;
    return ApiService.post("/v1/client/maintenance", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Maintenance saved successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((err) => console.log(err));
  }

  @Action({ rawError: true })
  [Actions.REMOVE_MAINTENANCE_IMAGE](props: RemoveMaintenanceImageProps) {
    const { maintenanceId, fileId } = props;

    return ApiService.delete("v1/client/maintenance/file", {
      params: {
        id: maintenanceId,
        file_id: fileId,
      },
    })
      .then((response) => {
        const data = response.data;
        if (data === "OK") {
          Swal.fire({
            title: "Image deleted successfully",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        return response;
      })
      .catch(({ response }) => {
        console.log(response);
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
