import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/map",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/",
        name: "map",
        component: () => import("@/views/Map.vue"),
      },
    ],
  },
  {
    path: "/map",
    redirect: "/map",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/map",
        name: "map",
        component: () => import("@/views/Map.vue"),
      },
    ],
  },
  {
    path: "/settings",
    redirect: "/settings",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/settings/account",
        name: "settings",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },
      {
        path: "/settings/geofences",
        name: "geofences",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },
      {
        path: "/settings/groups",
        name: "groups",
        component: () => import("@/views/settings/Groups.vue"),
      },
      {
        path: "/settings/drivers",
        name: "drivers",
        component: () => import("@/views/settings/Drivers.vue"),
      },
      {
        path: "/settings/calendars",
        name: "calendars",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },
      {
        path: "/settings/computed-attributes",
        name: "computed-attributes",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },
      {
        path: "/settings/maintenance",
        name: "maintenance",
        component: () => import("@/views/settings/Maintenances.vue"),
      },
      {
        path: "/settings/saved-commands",
        name: "saved-commands",
        component: () => import("@/views/settings/SavedCommands.vue"),
      },
      {
        path: "/settings/documents",
        name: "documents",
        component: () => import("@/views/settings/Documents.vue"),
      },
      {
        path: "/settings/alerts",
        name: "alerts",
        component: () => import("@/views/settings/Alerts.vue"),
      },
    ],
  },
  {
    path: "/testmap",
    redirect: "/testmap",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/testmap",
        name: "testmap",
        component: () => import("@/views/crafted/account/MapDrawingTest.vue"),
      },
    ],
  },
  {
    path: "/reports",
    redirect: "/reports",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/reports/route",
        name: "route",
        component: () => import("@/views/reports/RouteForm.vue"),
      },
      {
        path: "/reports/summary",
        name: "summary",
        component: () => import("@/views/reports/SummaryForm.vue"),
      },
      {
        path: "/reports/stops",
        name: "stops",
        component: () => import("@/views/reports/StopForm.vue"),
      },
      {
        path: "/reports/trips",
        name: "trips",
        component: () => import("@/views/reports/TripForm.vue"),
      },
      {
        path: "/reports/events",
        name: "events",
        component: () => import("@/views/reports/EventForm.vue"),
      },
      {
        path: "/reports/stops/map",
        name: "stops-map",
        component: () => import("@/views/reports/StopsMap.vue"),
        props: true,
      },
      {
        path: "/reports/routes/map",
        name: "routes-map",
        component: () => import("@/views/reports/RouteMap.vue"),
        props: true,
      },
      {
        path: "/reports/events/map",
        name: "events-map",
        component: () => import("@/views/reports/EventsMap.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-restore/:email",
        name: "password-restore",
        component: () => import("@/views/RestorePassword.vue"),
        props: true,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
