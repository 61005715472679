import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  AddGroupProps,
  DeleteGroupProps,
  EditGroupProps,
  Group,
  InviteUserProps,
} from "@/core/types/Group";

@Module
export default class GroupsModule extends VuexModule {
  groups: Group[] = [];

  get allGroups(): Group[] {
    return this.groups;
  }
  @Mutation
  [Mutations.SET_GROUPS](data: Group[]) {
    this.groups = data;
  }

  @Action({ rawError: true })
  [Actions.GET_GROUPS]() {
    return ApiService.get("v1/client", "groups")
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.ADD_GROUP](props: AddGroupProps) {
    const { name } = props;
    return ApiService.post("/v1/client/groups", { name })
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Group added",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.EDIT_GROUP](props: EditGroupProps) {
    const { name, id } = props;
    return ApiService.put(`/v1/client/groups?id=${id}`, { name })
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Group edited",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.DELETE_GROUP](props: DeleteGroupProps) {
    const { id } = props;
    return ApiService.delete(`/v1/client/groups?id=${id}`)
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "Group deleted",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.INVITE_USER](props: InviteUserProps) {
    const { user, _id } = props;
    return ApiService.post(`/v1/client/groups/invite?id=${_id}`, {
      sharedUsers: [user],
    })
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "User invited",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.REMOVE_INVITE](props: InviteUserProps) {
    const { user, userId, _id } = props;
    const body = userId ? { userId } : { email: user };

    return ApiService.post(`/v1/client/groups/remove?id=${_id}`, body)
      .then(({ status }) => {
        if (status === 200) {
          Swal.fire({
            title: "User removed",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        return status;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
