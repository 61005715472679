import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ReportsInfo {
  stopsData: any;
}

@Module
export default class ReportsModule extends VuexModule implements ReportsInfo {
  stopsData = {};
  tripsData = {};
  eventsData = {};

  get getStopsData() {
    return this.stopsData;
  }

  get getTripsData() {
    return this.tripsData;
  }

  get getEventsData() {
    return this.eventsData;
  }

  @Mutation
  [Mutations.SET_TRIPS_DATA](data: any) {
    this.tripsData = data;
  }

  @Mutation
  [Mutations.SET_STOP_DATA](data: any) {
    this.stopsData = data;
  }

  @Mutation
  [Mutations.SET_EVENTS_DATA](data: any) {
    this.eventsData = data;
  }

  @Action({ rawError: true })
  [Actions.TRIPS_REPORT](request) {
    return ApiService.get(
      "/v1/client",
      `reports/trips?deviceId=${
        request.deviceId
      }&from=${request.from.toISOString()}&to=${request.to.toISOString()}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRIPS_DATA, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.STOPS_REPORT](request) {
    return ApiService.get(
      "/v1/client",
      `reports/stops?deviceId=${
        request.deviceId
      }&from=${request.from.toISOString()}&to=${request.to.toISOString()}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STOP_DATA, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.EVENTS_REPORT](request) {
    return ApiService.get(
      "/v1/client",
      `reports/events?deviceId=${
        request.deviceId
      }&from=${request.from.toISOString()}&to=${request.to.toISOString()}&type=${
        request.type
      }`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENTS_DATA, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
