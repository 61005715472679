import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import ReportsModule from "@/store/modules/ReportsModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import DevicesModule from "@/store/modules/DevicesModule";
import NotificationModule from "@/store/modules/NotificationsModule";
import { Notification } from "@/core/types/Notification";
import MaintenancesModule from "@/store/modules/MaintenancesModule";
import GroupsModule from "@/store/modules/GroupsModule";
config.rawError = true;
const audio = new Audio("media/sounds/beep.mp3");

const state = {
  menuVisible: false,
  alertColor: "svg-icon-primary",
  alerts: [],
};

const mutations = {
  showMenu(state) {
    state.menuVisible = true;
  },
  hideMenu(state) {
    state.menuVisible = false;
  },
  newAlert(state) {
    state.alertColor = "svg-icon-danger";
    audio.play();
  },
  readAlert(state) {
    state.alertColor = "";
  },
};

const actions = {
  showMenu: ({ commit }) => commit("showMenu"),
  hideMenu: ({ commit }) => commit("hideMenu"),
  newAlert: ({ commit }) => commit("newAlert"),
  readAlert: ({ commit }) => commit("readAlert"),
};

const modules = {
  AuthModule,
  ReportsModule,
  BodyModule,
  BreadcrumbsModule,
  ConfigModule,
  DevicesModule,
  MaintenancesModule,
  GroupsModule,
  NotificationModule,
};

const store = createStore({
  state,
  actions,
  mutations,
  modules,
});
export default store;
