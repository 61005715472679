import { EventType } from "../types/Device";
import { EventShortcut } from "../types/EventShortcut";

export function convertToBase64(imageData) {
  // Create a canvas element to draw the image
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set the canvas dimensions equal to the image dimensions
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  // Create a new ImageData object with the provided Uint8ClampedArray
  const newImageData = new ImageData(
    imageData.data,
    imageData.width,
    imageData.height,
    {
      colorSpace: "srgb",
    }
  );

  // Draw the image data onto the canvas
  context!.putImageData(newImageData, 0, 0);

  // Convert the canvas content to a base64 string
  const base64String = canvas.toDataURL();

  // Remove the data URL prefix to obtain only the base64 string
  return base64String.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
}

export function base64ToFile(base64String, filename, mimeType) {
  base64String = base64String.replace(
    /^data:image\/(png|jpg|jpeg);base64,/,
    ""
  );
  const byteCharacters = atob(base64String);
  const byteArrays = [] as any;
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: mimeType });
  return new File([blob], filename, { type: mimeType });
}

export function getImageSizeInBytesFromBase64(base64String) {
  const paddingLength = base64String.endsWith("==")
    ? 2
    : base64String.endsWith("=")
    ? 1
    : 0;
  const sizeInBytes = base64String.length * 0.75 - paddingLength;
  return sizeInBytes;
}

export function diff_minutes(dt2, dt1) {
  let diff = ((dt2.getTime() - dt1.getTime()) / 1000) / 60;
  return Math.abs(Math.round(diff));
}

export function diff_hours(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}

export function diff(dt2, dt1) {
  return timeFormat(dt2.getTime() - dt1.getTime());
}

export function timeFormat(t: number) {
  t /= 1000;

  let l = "S";
  let extra = "";
  if (t >= 59.5) {
    t /= 60; // 10
    l = Math.round(t) == 1 ? "Min" : "Min";

    if (t >= 60) {
      t /= 60; // t = n hours
      l = Math.round(t) == 1 ? "H" : "H";

      if (t >= 24) {
        const x: number = Math.round(t % 24);
        if (x > 0) extra = x == 1 ? x + " H" : x + " H";
        t /= 24;
        l = Math.round(t) == 1 ? "D" : "D";
        // t = n days

        if (t >= 7) {
          // if n of days > 7 days per week
          t /= 7;
          const d: number = Math.round((t * 7) % 7);
          if (d > 0) extra = d == 1 ? d + " D" : d + " D";
          l = Math.round(t) == 1 ? "Sem" : "Sem";
        }
      } else {
        if (l == "H" || l == "H" || l == "H" || l == "H") {
          const x: number = Math.round((t * 60) % 60);
          if (x > 0) extra = x + " Min";
        }
      }
    }
  }
  const r: string = t.toFixed(0) + " " + l + " " + extra.trimEnd();

  if (r.startsWith("-") || r.startsWith("0") || l == "S") {
    return "Now";
  }
  return r + " ago";
}

export function datetimeFormat(date: Date | string) {
  date = typeof date === "string" ? new Date(date) : date;

  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    " " +
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0")
  );
}

export function odometerFormat(o: number, t?: string) {
  return (
    (o / 1000)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    " " +
    (t === "us" ? "miles" : "kms")
  );
}

export function speedFormat(s: number, t?: string) {
  return (
    (s / (t === "us" ? 1.609344 : 1))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    " " +
    (t === "us" ? "miles" : "kms")
  );
}

export function distanceFormat(s: number, t?: string) {
  return (
    (s / (t === "us" ? 1609.344 : 1000))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    " " +
    (t === "us" ? "miles" : "km")
  );
}

export function calculateSpeed(speed = 0) {
  const calculatedSpeed = speed
    ? Number.parseFloat((speed * 1.852).toFixed(0))
    : 0;
  return calculatedSpeed;
}

export function getDateRangeFromShortcut(s: EventShortcut) {
  const from = new Date(),
    to = new Date();
  switch (s) {
    case EventShortcut.LastHour:
      from.setHours(new Date().getHours() - 1);
      return {
        from: from,
        to: to,
      };
    case EventShortcut.Today:
      from.setHours(0, 0, 0, 0);
      return {
        from: from,
        to: to,
      };
    case EventShortcut.Yesterday:
      from.setDate(from.getDate() - 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(0, 0, 0, 0);
      return {
        from: from,
        to: to,
      };
  }
}
