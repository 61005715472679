import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    map: "Map",
    features: "Features",
    reports: "Reports",
    route: "Route",
    summary: "Summary",
    stops: "Stops",
    alerts: "Alerts",
    alertType: "Alert type",
    trips: "Trips",
    events: "Events",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    createdAt: "Created at",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    unexpectedError: "Unexpected server error, please try again later",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    profileDetails: "Profile Details",
    allowedImageTypes: "Allowed file types: png, jpg, jpeg.",
    fullName: "Full Name",
    company: "Company",
    contactPhone: "Contact Phone",
    companySite: "Company Site",
    country: "Country",
    timezone: "Time Zone",
    currency: "Currency",
    communication: "Communication",
    allowMarketing: "Allow Marketing",
    discard: "Discard",
    saveChanges: "Save Changes",
    pleaseWaitIndicatorText: "Please Wait...",
    displaySettings: "Display Settings",
    distanceUnit: "Distance Unit",
    volumeUnit: "Volume Unit",
    liters: "Liters",
    gallons: "Gallons",
    kilometers: "Kilometers",
    miles: "Miles",
    geofences: "Geofences",
    groups: "Groups",
    drivers: "Drivers",
    calendars: "Calendars",
    computedAttributes: "Computed Attributes",
    maintenance: "Maintenance",
    savedCommands: "Saved Commands",
    driverName: "Driver Name",
    driverId: "Driver ID",
    groupName: "Group Name",
    name: "Name",
    time: "Time",
    start: "Start",
    period: "Period",
    type: "Type",
    description: "Description",
    sendSMS: "Send SMS",
    expDate: "Expiration Date",
    alertDate: "Alert Date",
    device: "Device",
    file: "File",
    document: "Document",
    addNew: "Add new",
    edit: "Edit",
    selectDevice: "Select a device",
    expiresAt: "Expires At",
    remindMeAt: "Remind Me At",
    cancel: "Cancel",
    maintenanceBy: "Maintenance by",
    observations: "Observations",
    startAt: "Started at",
    endedAt: "Ended at",
    maintenanceType: "Maintenance Type",
    add: "Add",
    delete: "Delete",
    addExpenses: "Add Expenses",
    selectRowText: "Select a row to be able to edit, delete or add expenses",
    corrective: "Corrective",
    preventive: "Preventive",
    showFiles: "Show Files",
    noRecordsFound: "No records found",
    close: "Close",
    group: "Group",
    resendInvite: "Resend",
    deleteInvite: "Remove",
    user: "User",
    invite: "Invite",
    accepted: "Accepted",
    pending: "Pending",
    email: "Email",
  },
  es: {
    dashboard: "Tablero",
    map: "Mapa",
    features: "Funcionalidades",
    reports: "Reportes",
    route: "Ruta",
    summary: "Resumen",
    stops: "Paradas",
    alerts: "Alertas",
    alertType: "Tipo de alerta",
    trips: "Viajes",
    events: "Eventos",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    createdAt: "Fecha de creación",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    unexpectedError:
      "Error inesperado del servidor, por favor intente más tarde",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
    profileDetails: "Detalles del perfil",
    allowedImageTypes: "Tipo de archivos permitidos: png, jpg, jpeg.",
    fullName: "Nombre Completo",
    company: "Compañia",
    contactPhone: "Teléfono de contacto",
    companySite: "Sitio web",
    country: "País",
    timezone: "Zona Horaria",
    currency: "Moneda",
    communication: "Comunicación",
    allowMarketing: "Permitir Mercadotecnia",
    discard: "Descartar",
    saveChanges: "Guardar cambios",
    pleaseWaitIndicatorText: "Espere porfavor...",
    displaySettings: "Configuración de unidades",
    distanceUnit: "Unidad en distancias",
    volumeUnit: "Unidad en volumenes",
    liters: "Litros",
    gallons: "Galones",
    kilometers: "Kilometros",
    miles: "Millas",
    geofences: "Geofenzas",
    groups: "Grupos",
    drivers: "Conductores",
    calendars: "Calendarios",
    computedAttributes: "Atributos Calculados",
    maintenance: "Mantenimiento",
    maintenances: "Mantenimientos",
    savedCommands: "Comandos Guardados",
    driverName: "Nombre del conductor",
    driverId: "Identificador del conductor",
    groupName: "Nombre del grupo",
    name: "Nombre",
    time: "Tiempo",
    start: "Inicio",
    period: "Periodo",
    type: "Tipo",
    description: "Descripción",
    sendSMS: "Enviar SMS",
    expDate: "Fecha de Expiración",
    alertDate: "Fecha de Alerta",
    device: "Dispositivo",
    file: "Archivo",
    document: "Documento",
    addNew: "Agregar",
    edit: "Editar",
    selectDevice: "Selecciona un dispositivo",
    expiresAt: "Expira el",
    remindMeAt: "Recuérdame el",
    cancel: "Cancelar",
    maintenanceBy: "Realizado por",
    observations: "Observaciones",
    startAt: "Inicio",
    endedAt: "Finalizo",
    maintenanceType: "Tipo de mantenimiento",
    add: "Agregar",
    delete: "Eliminar",
    addExpenses: "Agregar Gastos",
    selectRowText:
      "Seleccione un registro para poder editar, eliminar o agregar gastos.",
    corrective: "Correctivo",
    preventive: "Preventivo",
    showFiles: "Mostrar Archivos",
    noRecordsFound: "No se encontraron registros",
    close: "Cerrar",
    group: "Grupo",
    resendInvite: "Re enviar",
    deleteInvite: "Remover",
    user: "Usuario",
    invite: "Invitacion",
    accepted: "Aceptada",
    pending: "Pendiente",
    email: "Correo",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
