enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  REGISTER_DEVICE = "registerDevice",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  RESTORE_PASSWORD = "restorePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_DEVICES = "getDevices",

  GET_ALERTS = "getAlerts",

  STOPS_REPORT = "stopsReport",
  ROUTES_REPORT = "routesReport",
  TRIPS_REPORT = "tripsReport ",
  EVENTS_REPORT = "eventsReport",

  // maintenance
  GET_MAINTENANCES = "getMaintenances",
  DELETE_MAINTENANCE = "deleteMaintenance",
  EDIT_MAINTENANCE = "editMaintenance",
  ADD_MAINTENANCE = "addMaintenance",
  REMOVE_MAINTENANCE_IMAGE = "removeMaintenanceImage",

  // groups
  GET_GROUPS = "getGroups",
  ADD_GROUP = "addGroup",
  EDIT_GROUP = "editGroup",
  DELETE_GROUP = "deleteGroup",
  INVITE_USER = "inviteUser",
  REMOVE_INVITE = "removeInvite",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_STOP_DATA = "setStopData",
  SET_ROUTE_DATA = "setRouteData",
  SET_TRIPS_DATA = "setTripsData",
  SET_EVENTS_DATA = "setEventsData",

  SET_DEVICES = "setDevices",

  SET_ALERTS = "setAlerts",

  SET_VOLUME_UNIT = "setVolumeUnit",
  SET_DISTANCE_UNIT = "setDistanceUnit",

  // maintenances
  SET_MAINTENANCES = "setMaintenances",

  // groups
  SET_GROUPS = "setGroups",
}

export { Actions, Mutations };
