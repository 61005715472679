import ApiService from "@/core/services/ApiService";
import { Alert } from "@/core/types/Alert";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import * as utils from "../../core/helpers/utils";
@Module
export default class AlertModule extends VuexModule {
  alerts: Alert[] = [];

  get allAlerts(): Alert[] {
    return this.alerts;
  }
  @Mutation
  [Mutations.SET_ALERTS](data: Alert[]) {
    this.alerts = data;
  }

  @Action({ rawError: true })
  [Actions.GET_ALERTS]() {
    const alerts: Alert[] = [];
    return ApiService.get("v1/client", "alerts")
      .then(({ data }) => {
        const alert: Alert = {
          id: data._id,
          status: data.status,
          device: data,
          position: data.position,
          type: data.type,
          comments: data.comments,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };
        alerts.push(alert);
        this.context.commit(Mutations.SET_ALERTS, alerts);
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
